import axios, { AxiosError } from 'axios';
import queryString from 'query-string';

import { Preferences } from '@capacitor/preferences';

import { storageKeys } from '@/consts/storageKeys.ts';
import {
  IRequestSearchFilter,
  IRequestSearchFilterTypeEnum,
} from '@/types/IRequestSearchFilter.ts';
import { GOBAZAR_CONFIG } from '@/utils/configs/gobazarConfig.ts';
import timeout from '@/utils/configs/timeout.ts';
import { notify } from '@/utils/notify.tsx';

axios.interceptors.request.use(async (config) => {
  config.baseURL = `${GOBAZAR_CONFIG.API_URL}/`;

  const search: undefined | string | IRequestSearchFilter = config.params?.search;
  if (search && typeof search === 'object' && search?.isFilter === true) {
    let searchString =
      search.unspecified
        ?.filter((val) => !val)
        .map((val) => val?.toString())
        .join(',') || '';

    Object.entries(search).forEach(([key, filter]) => {
      if (
        typeof filter == 'object' &&
        !Array.isArray(filter) &&
        filter.filterType &&
        filter.value !== undefined &&
        filter.value !== '' &&
        filter.value !== null
      ) {
        searchString += `,${key}${IRequestSearchFilterTypeEnum[filter.filterType]}${filter.value.toString()}`;
      }
    });

    config.params.search = searchString.startsWith(',') ? searchString.substring(1) : searchString;
  }

  config.paramsSerializer = {
    serialize: (params) => queryString.stringify(params, { arrayFormat: 'comma', skipNull: true }),
  };
  //     config.headers['Content-Type'] = 'application/json';
  //     config.headers['Accept'] = 'application/json';

  if (config.headers && !config.url?.includes('http')) {
    if (config.headers['Content-Type'] != 'multipart/form-data') {
      config.headers['Content-Type'] = 'application/json';
    }

    if (!config.url?.startsWith('/user-service/auth/guest')) {
      let token = await Preferences.get({ key: storageKeys.token });

      for (let i = 0; i < 16; i++) {
        if (!token.value) {
          await timeout(500);
          token = await Preferences.get({ key: storageKeys.token });
        } else {
          break;
        }
      }

      if (token.value) {
        config.headers['Authorization'] = `Bearer ${token.value}`;
      }
    }
  }

  // if (GOBAZAR_CONFIG.IS_DEBUGGING) {
  //   console.debug('---AXIOS---', config.url, config.params);
  //   if (config.params?.search) {
  //     console.debug('---AXIOS SEARCH FILTER---', config.params.search);
  //   }
  // }

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  async (error: AxiosError<{ message?: string }>) => {
    if (error && error.response) {
      if (error.response.status >= 500) {
        notify('error', 'Server error. Try to refresh the page');
      } else if (error.response.status >= 600 && error.response.data?.message) {
        notify('error', error.response.data.message);
      }

      return Promise.reject(error);
    }
  },
);

export default axios;
